import { render, staticRenderFns } from "./communicate.vue?vue&type=template&id=e9153792&scoped=true&"
import script from "./communicate.vue?vue&type=script&lang=js&"
export * from "./communicate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e9153792",
  null
  
)

export default component.exports